import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {FloatingDropdown} from 'wix-ui-tpa/cssVars'
import type {SetLocation} from '../../../actions/filter'
import type {ScheduleLocation} from '../../../services/schedule'
import {classes} from './locations.st.css'

export interface LocationsProps {
  locations: ScheduleLocation[]
  t: Function
  selectedLocationId: string
  onClick: SetLocation
}

export const Locations = ({locations, t, selectedLocationId, onClick}: LocationsProps) => {
  if (!locations.length) {
    return null
  }

  return (
    <div className={classes.container}>
      <FloatingDropdown
        options={locations}
        className={classes.root}
        aria-label={`${t('schedulePlaces.filter')} ${locations.find(({id}) => id === selectedLocationId)?.value}`}
        label={t('schedulePlaces.filter')}
        data-hook={DH.LOCATION_DROPDOWN}
        value={selectedLocationId}
        onChange={selectedOption => onClick(selectedOption.id)}
      />
    </div>
  )
}
