import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {useSettings} from '../../../../hooks/settings'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import settingsParams from '../../../../settingsParams'
import {useWidgetProps} from '../../../hooks/widget-props'
import {PersonalAgendaHeader} from '../personal-agenda-header'
import {MobileHeaderProps} from './interfaces'
import s from './mobile-header.scss'

export const MobileHeader = ({alignmentClass}: MobileHeaderProps) => {
  const {eventTitle, formattedEventDateAndLocation, installedApps, personalAgendaEnabled} = useWidgetProps()
  const settings = useSettings()
  const {
    allBreakpoints: {isDateAndLocationVisible},
  } = useVisibilityStyles()

  return (
    <div className={alignmentClass}>
      {personalAgendaEnabled ? (
        <PersonalAgendaHeader membersAreaInstalled={installedApps.eventsInMembersInstalled} />
      ) : null}
      <h1 className={s.titleMobile} data-hook={DH.SCHEDULE_TITLE}>
        {settings.get(settingsParams.scheduleTitleText) as string}
      </h1>
      {isDateAndLocationVisible() ? (
        <div className={s.dateLocationMobile}>
          <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
          <span>{formattedEventDateAndLocation}</span>
        </div>
      ) : null}
    </div>
  )
}
