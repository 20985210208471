import {useEnvironment} from '@wix/yoshi-flow-editor'
import React, {useState} from 'react'
import {
  ActionsMenuLayout,
  ActionsMenuLayoutItemAlignment,
  Avatar,
  Button,
  ButtonPriority,
  ButtonSize,
  Popover,
} from 'wix-ui-tpa/cssVars'
import {User} from '../../../../../../icons/User'
import {useWidgetProps} from '../../../hooks/widget-props'
import {classes as c} from './button.st.css'
import s from './login.scss'

export const Login = () => {
  const {member, promptLogin, logout, t, toggleLoginDrawer} = useWidgetProps()
  const {isMobile} = useEnvironment()
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)

  const handleOpenPopup = () => {
    if (isMobile) {
      toggleLoginDrawer()
    } else {
      setPopoverOpen(true)
    }
  }

  const handleClosePopup = () => {
    setPopoverOpen(false)
  }

  const {user, imageUrl} = member
  const loggedIn = Boolean(user)

  if (loggedIn) {
    const handleLogout = () => {
      logout()
    }

    return (
      <Popover
        shown={popoverOpen}
        placement="bottom-end"
        onClickOutside={handleClosePopup}
        contentClassName={s.popoverContent}
        minWidth={150}
      >
        <Popover.Element>
          <div onClick={handleOpenPopup} className={s.avatar}>
            <Avatar src={imageUrl} />
          </div>
        </Popover.Element>
        <Popover.Content>
          <ActionsMenuLayout className={s.menu} alignment={ActionsMenuLayoutItemAlignment.left}>
            <ActionsMenuLayout.Item onClick={handleLogout} content={t('logout')} />
          </ActionsMenuLayout>
        </Popover.Content>
      </Popover>
    )
  }

  const handleLogin = () => {
    promptLogin()
  }

  return isMobile ? (
    <User className={s.mobileButton} onClick={() => toggleLoginDrawer()} />
  ) : (
    <Button
      className={c.root}
      onClick={handleLogin}
      prefixIcon={undefined}
      priority={ButtonPriority.basicSecondary}
      size={ButtonSize.tiny}
      upgrade
    >
      {t('login')}
    </Button>
  )
}
