import React, {PropsWithChildren} from 'react'

interface TimeProps {
  className?: string
  dateTime?: string
}

export const Time = ({children, dateTime, className}: PropsWithChildren<TimeProps>) => (
  <time className={className} dateTime={dateTime}>
    {children || <>&nbsp;</>}
  </time>
)
