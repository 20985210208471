import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ChevronRight} from '@wix/wix-ui-icons-common'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {useWidgetProps} from '../../hooks/widget-props'
import {getFilterButtonText} from '../../selectors/filter'
import s from './filters.scss'
import {Locations} from './locations'
import {Tags} from './tags'

export const Filters = () => {
  const {t, selectedLocation, setLocation, locations, tags, toggleTag, filter, toggleFiltersModal} = useWidgetProps()
  const {isMobile} = useEnvironment()
  const {
    allBreakpoints: {isFiltersVisible},
  } = useVisibilityStyles()

  if (isMobile) {
    if (!isFiltersVisible()) {
      return null
    }
    return (
      <button data-hook={DH.MOBILE_FILTER_BUTTON} className={s.mobileFilterButton} onClick={() => toggleFiltersModal()}>
        <span className={s.mobileFilterButtonText}>{getFilterButtonText(filter, t)}</span>
        <ChevronRight />
      </button>
    )
  }

  return (
    <div className={s.desktopSpacing}>
      {isFiltersVisible() ? (
        <div className={s.container} data-hook={DH.FILTERS_SECTION}>
          <Locations locations={locations} t={t} selectedLocationId={selectedLocation} onClick={setLocation} />
          <Tags filters={true} t={t} tags={tags} onClick={toggleTag} />
        </div>
      ) : null}
    </div>
  )
}
