import {
  ISiteTextPreset,
  IStyleParam,
  IWixStyleFont,
  StyleParamType,
  createStylesParams,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings'
import {GetDefaultValueProps} from '../../settings-commons/utils/style'

type IStylesParamsV1 = {
  filtersTextFont: StyleParamType.Font
  headerTitleFont: StyleParamType.Font
  headerTextFont: StyleParamType.Font
  viewMyAgendaLinkFont: StyleParamType.Font
  dateDividerTextFont: StyleParamType.Font
  timeSlotTextFont: StyleParamType.Font
  durationTextFont: StyleParamType.Font
  scheduleItemTitleTextFont: StyleParamType.Font
  scheduleItemLocationTextFont: StyleParamType.Font
  buttonTextFont: StyleParamType.Font
}

type IStylesParamsV2 = {
  filtersTextFontV2: StyleParamType.Font
  headerTitleFontV2: StyleParamType.Font
  headerTextFontV2: StyleParamType.Font
  viewMyAgendaLinkFontV2: StyleParamType.Font
  dateDividerTextFontV2: StyleParamType.Font
  timeSlotTextFontV2: StyleParamType.Font
  durationTextFontV2: StyleParamType.Font
  scheduleItemTitleTextFontV2: StyleParamType.Font
  scheduleItemLocationTextFontV2: StyleParamType.Font
  buttonTextFontV2: StyleParamType.Font
}

export type IStylesParams = {
  backgroundColor: StyleParamType.Color
  headerTextColor: StyleParamType.Color
  headerTitleColor: StyleParamType.Color
  filtersTextColor: StyleParamType.Color
  dateDividerTextColor: StyleParamType.Color
  dateDividerColor: StyleParamType.Color
  dateDividerWidth: StyleParamType.Number
  timeSlotTextColor: StyleParamType.Color
  durationTextColor: StyleParamType.Color
  scheduleItemDividerColor: StyleParamType.Color
  scheduleItemDividerWidth: StyleParamType.Number
  scheduleItemTitleTextColor: StyleParamType.Color
  scheduleItemLocationTextColor: StyleParamType.Color
  scheduleItemDescriptionTextColor: StyleParamType.Color
  scheduleItemDescriptionTextFont: StyleParamType.Font
  tagTextColor: StyleParamType.Color
  tagColor: StyleParamType.Color
  hollowButtonTextColor: StyleParamType.Color
  buttonBorderColor: StyleParamType.Color
  fullButtonTextColor: StyleParamType.Color
  buttonBackgroundColor: StyleParamType.Color
  buttonBorderWidth: StyleParamType.Number
  buttonBorderRadius: StyleParamType.Number
  readMoreTextColor: StyleParamType.Color
  showDateAndLocation: StyleParamType.Boolean
  showButton: StyleParamType.Boolean
  showFilters: StyleParamType.Boolean
  showDuration: StyleParamType.Boolean
  headerTextAlignment: StyleParamType.Number
  buttonStyle: StyleParamType.Number
} & IStylesParamsV1 &
  IStylesParamsV2

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

const getScheduleDefaultV2Font = (
  params: GetDefaultValueProps,
  fontParam: IStyleParam<StyleParamType.Font>,
  mobileFontSize: number,
  preset: keyof ISiteTextPreset,
) => {
  const {isMobile, getStyleParamValue} = params
  const fontValue = getStyleParamValue(fontParam)

  if (isMobile) {
    const {value: _value, preset: _preset, ...rest} = fontValue
    return getDefaultFont(preset, {...rest, size: mobileFontSize})(params)
  }

  return fontValue
}

const styleParamsV1 = createStylesParams<IStylesParamsV1>({
  filtersTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getDefaultFont('Page-title', {
        size: params.isMobile ? 30 : 60,
      })(params),
  },
  headerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getDefaultFont('Body-M', {
        size: params.isMobile ? 14 : 18,
      })(params),
  },
  viewMyAgendaLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  dateDividerTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getDefaultFont('Page-title', {
        size: params.isMobile ? 16 : 20,
      })(params),
  },
  timeSlotTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  durationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  scheduleItemTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  scheduleItemLocationTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 14,
    }),
  },
  buttonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
})

const styleParamsV2 = createStylesParams<IStylesParamsV2>({
  filtersTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.filtersTextFont, 16, 'Body-M'),
  },
  headerTitleFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.headerTitleFont, 30, 'Page-title'),
  },
  headerTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.headerTextFont, 14, 'Body-M'),
  },
  viewMyAgendaLinkFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.viewMyAgendaLinkFont, 16, 'Body-M'),
  },
  dateDividerTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.dateDividerTextFont, 16, 'Page-title'),
  },
  timeSlotTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.timeSlotTextFont, 16, 'Body-M'),
  },
  durationTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.durationTextFont, 14, 'Body-M'),
  },
  scheduleItemTitleTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.scheduleItemTitleTextFont, 16, 'Body-M'),
  },
  scheduleItemLocationTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getScheduleDefaultV2Font(params, styleParamsV1.scheduleItemLocationTextFont, 14, 'Body-M'),
  },
  buttonTextFontV2: {
    type: StyleParamType.Font,
    getDefaultValue: params => getScheduleDefaultV2Font(params, styleParamsV1.buttonTextFont, 16, 'Body-M'),
  },
})

export default createStylesParams<IStylesParams>({
  ...styleParamsV1,
  ...styleParamsV2,
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: params =>
      getDefaultFont('Page-title', {
        size: params.isMobile ? 30 : 60,
      })(params),
  },
  dateDividerTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  timeSlotTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  durationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  scheduleItemDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dateDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleItemTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemLocationTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemDescriptionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleItemDescriptionTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  dateDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tagTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  tagColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  fullButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hollowButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  buttonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  filtersTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  readMoreTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  showDateAndLocation: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showButton: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  showDuration: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => undefined,
  },
  headerTextAlignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
  buttonStyle: {
    type: StyleParamType.Number,
    getDefaultValue: () => undefined,
  },
})

export {customCssVars} from './custom-css-vars'
